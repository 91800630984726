
import moment from 'moment-timezone'
// **********************************************************************************************

export const formDateObjectToUTCDate = (dateTime) => {
  return dateTime
}

// **********************************************************************************************

export const sessionToStartUTCDate = (session) => {
  return new Date(session.startTime);
}

export const sessionToEndUTCDate = (session) => {
  return new Date(session.endTime);
}

// **********************************************************************************************

export function format2Digits(value) {
  if (value < 10) {
    return "0" + value;
  } else {
    return value;
  }
};

/**
 * Provides date and time format based on user language preference ( stored in localStorage )\
 * Temporary language check ( wrong internationalization stored ) -- 'en' and 'fr'
 *    - 'en' --> by default will be Great Britain (DD/MM/YYYY)--> we want 'en-US' instead (M/D/YYYY)
 *    - 'fr' --> by default will be France as expected (D/MM/YYYY)\
 * --> meaning so far the code is not correctly implemented
 */
const detectDateAndTimeFormat = () => {
  const language = localStorage.getItem("keyLang");
  // Currently handling 2 country formats: en --> US, (fr --> FR ⚠️ statically
  const timeFormat = language === 'en' ? 'h:mm:ss A': 'HH:mm:ss';
  const dateFormat = language === 'en' ? 'M/D/YYYY' : 'D/MM/YYYY';

  return {
    timeFormat,
    dateFormat
  }
}
// **********************************************************************************************
// Clean use --> LP --> can be used
export function formatTime(utcDate) {
  const timeFormat = detectDateAndTimeFormat().timeFormat;
  return moment(utcDate).format(timeFormat);
};

export function formatDate(utcDate){
  const dateFormat = detectDateAndTimeFormat()?.dateFormat;
  return moment(utcDate).format(dateFormat)
}

export function formatCompleteDate(utcDate){
  const date = formatDate(utcDate);
  const time = formatTime(utcDate);
  return `${date} - ${time}`;
}

window.formatCompleteDate = formatCompleteDate

/** Formats date without timezone indicator == restituted from CSV columns date & time
 * @param {*} recordDateValue: Date string value (format ex: "2022-10-19T19:03:13.000Z")
 * @returns xx/xx/xx - xx:xx:xx based on language
 * 
 * - identify the raw ISO time (time string reconstituted by dev turned to Date string)
 * - parse ISO separators and indicators to get isolated date & time ( removing: T & .xxxZ )
 * - extracts parsed and isolated date and time
 * - format date & time according to language specifics
 * - returns desired language based date format
*/
export const extractCompleteDateFromCSVDate = recordDateValue => {
  let date, time;

  // Identifies ISO time string date value
  const patternTZ = /(T|\.\d{3}Z)/g;
  const isDateWithoutTZ = patternTZ.test(recordDateValue);

  // Parsing and date & time isolation
  if( isDateWithoutTZ){
    const [ _date, _time ] = recordDateValue
      .replaceAll(patternTZ, ' ').trim()
      .split(' ')
      date = _date;
      time = _time;
  }

  // Gets language specifics formats
  const { dateFormat, timeFormat } = detectDateAndTimeFormat();

  // Build a valid readable string date with time
  const dateString = `${date} ${time}`;
  
  // Formats date & time
  const formattedDate = moment(dateString).format(dateFormat);
  const formattedTime = moment(dateString).format(timeFormat);

  // Returns desired date & time based on language
  return`${ formattedDate } - ${ formattedTime }`;
}

export function extractTimeFromDateString(dateString) {
  return dateString.match(/(\d{1}|\d{2}):\d{2}:\d{2}/)?.[0];

}




// **********************************************************************************************
// Legacy use [ formatSessionStartDate, formatSessionStartDateTime, formatSessionEndDateTime] with adjustment


// Legacy to review -  no need to pass whole record
export const formatSessionStartDate = (record) => {
  const date = new Date(record.startTime);
  return formatDate(date);
};

// Legacy to review - no need to pass whole record
export const formatSessionStartDateTime = (record) => {
  const utcDate = new Date(record.startTime);
  const date = formatDate(utcDate);
  const time = formatTime(utcDate);
  return `${date} - ${time}`;
}
// Legacy to review - use moment instead - no need to pass whole record
export const formatSessionEndDateTime = (record) => {
  const utcDate = new Date(record.endTime);
  const date    = formatDate(utcDate);
  const time    = formatTime(utcDate);
  return `${date} - ${time}`;
}

