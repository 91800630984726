import * as React from "react";
import moment from 'moment-timezone';
import { useState, useEffect } from "react";
import axios from "axios";
import "./RecordListBySessionMultiple.css";
import { Input, Table, Select, Button, Modal } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import RecordNameItemBySessionMultiple from "../item/RecordNameItemBySessionMultiple";
import RecordAthleteItemBySessionMultiple from "../item/RecordAthleteItemBySessionMultiple";
import RecordStartItemBySessionMultiple from "../item/RecordStartItemBySessionMultiple";
import RecordEndItemBySessionMultiple from "../item/RecordEndItemBySessionMultiple";
import RecordActionItemBySessionMultiple from "../item/RecordActionItemBySessionMultiple";
import RecordMobileItemBySessionMultiple from "../item/RecordMobileItemBySessionMultiple";

window.moment =  moment


function RecordListBySessionMultiple({ t, recordsWithTracker }) {
  const { id } = useParams();
  const navigate = useNavigate();

  /**
   * TODO: (later) LP > LP
   * Refactor dates states related (days, months, years) into a reusable hook
   * ( used in SessionRaw.jsx and here )
   */
  const [days, setDays] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [months, setMonths] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  // TODO: LP - not maintainable years 
  const [years, setYears] = useState([2023, 2022, 2021]);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(true);
  const [shouldShowTutos, setShouldShowTutos] = useState(false);

  const isMobile = window.innerWidth < 700;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const Option = Select.Option;

  useEffect(() => {
    // GET /users/:id
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`,
        { headers: { "x-access-token": accessToken }
      })
      .then((response) => {
        if (response.data.hasConnectedOnce === false) setShouldShowTutos(true);
      })
      .catch(error => console.error( "user not found", error));
  });

  const handleCancel = (e) => {
    e.stopPropagation();
    // PUT /users/:id - modifies `hasConnectedOnce`
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`,
        { hasConnectedOnce: true },
        { headers: { "x-access-token": accessToken }}
      )
      .then((res) => {
        setVisible(false);
      });
  };

  // REVIEW: LP > for each table columns a request is made ( could be preset using redux thunk / redux saga + redux )
  const columns = [
    {
      title: t("Name"),
      dataIndex: "title",
      key: "name",
      responsive: ["sm"],
      render: (_, record) => (
        <RecordNameItemBySessionMultiple record={record} />
      ),
    },
    {
      title: t("Athlete"),
      dataIndex: "athlete",
      key: "athlete",
      responsive: ["sm"],
      render: (_, record) => (
        <RecordAthleteItemBySessionMultiple record={record} />
      ),
    },
    /** TODO: [RecordStartItemBySessionMultiple, RecordEndItemBySessionMultiple] same code implementation
     *  To refacto into branch dev: use one component instead
     *  */
    {
      title: t("Start"),
      key: "start",
      responsive: ["sm"],
      render: (record) => <RecordStartItemBySessionMultiple record={record} />
    },
    {
      title: t("End"),
      key: "end",
      responsive: ["sm"],
      render: (record) => <RecordEndItemBySessionMultiple record={record} />,
    },
    {
      title: "Actions",
      key: "actions",
      responsive: ["sm"],
      render: (_, record) => (
        <RecordActionItemBySessionMultiple record={record} />
      ),
    },
    {
      title: "",
      key: "responsive",
      responsive: ["xs"],
      render: (_, record) => {
        return <RecordMobileItemBySessionMultiple record={record} />;
      },
    },
  ];

  const dataSource = recordsWithTracker
    .flat(1)
    // .map((record) => ({ key: record.uuid, ...record, startTime: new Date(record.startTime).toUTCString() }));
    .map((record) => ({ key: record.uuid, ...record }));

  return (
    <>
      <div style={{ textAlign: "left", marginBottom: "3%", paddingTop: "3%" }}>
        <Button
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          type="primary"
          htmlType="submit"
          className="session-form-button"
          onClick={() => navigate(`/${id}/raw-sessions`)}
        >
          <img
            style={{
              flex: 1,
              maxWidth: "111px",
              maxHeight: "40px",
              margin: "auto",
            }}
            alt="Green slow"
            src={require("../../../assets/picto-green-slow.png")}
          />
          <p style={{ flex: "4", marginBottom: "0", marginLeft: "3%" }}>
            {t("Download data from tracker")}
          </p>
        </Button>
      </div>
      <div style={{ textAlign: "left", marginBottom: "3%", paddingTop: "3%" }}>
        <Input
          style={{
            width: "auto",
            backgroundColor: "transparent",
            border: "1px solid",
          }}
          name="name"
          placeholder={t("Search by name")}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      <div style={{ textAlign: "left", marginBottom: "3%" }}>
        <div className="selectSubContainer">
          <Select
            mode="multiple"
            onChange={value => {
              if ( !value.length || value?.includes("all") ) {
                setDays([0, 1, 2, 3, 4, 5, 6]);
              } else setDays(value);
            }}
            allowClear
            placeholder={t("Days")}
            className="selector"
          >
            {[
              t("Sunday"),
              t("Monday"),
              t("Tuesday"),
              t("Wednesday"),
              t("Thursday"),
              t("Friday"),
              t("Saturday"),
            ].map((day, index) => {
              return (
                <Option value={index} key={day}>
                  {day}
                </Option>
              );
            })}
            <Option value="all">{t("All")}</Option>
          </Select>
          <Select
            mode="multiple"
            onChange={(e) => {
              if ((e.includes("all")) || e.length === 0) {
                setMonths([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
              } else setMonths(e);
            }}
            allowClear
            placeholder={t("Months")}
            className="selector"
          >
            {[
              t("January"),
              t("February"),
              t("March"),
              t("April"),
              t("May"),
              t("June"),
              t("July"),
              t("August"),
              t("September"),
              t("October"),
              t("November"),
              t("December"),
            ].map((month, index) => {
              return (
                <Option value={index} key={month}>
                  {month}
                </Option>
              );
            })}
            <Option value="all">{t("All")}</Option>
          </Select>
          <Select
            mode="multiple"
            onChange={(e) => {
              if ((e && e.length && e.includes("all")) || e.length === 0) {
                setYears([2023, 2022, 2021]);
              } else setYears(e);
            }}
            allowClear
            placeholder={t("Years")}
            className="selector"
            style={{ marginRight: "0%" }}
          >
            {[2023, 2022, 2021].map((year) => (
              <Option value={year} key={year}>
                {year}
              </Option>
            ))}
            <Option value={"all"} key={"all"}>
              {t("All")}
            </Option>
          </Select>
        </div>
      </div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={dataSource}
      />
      {shouldShowTutos && (
        <Modal
          className="athlete-modal"
          style={{ textAlign: "center" }}
          visible={visible}
          onCancel={handleCancel}
          cancelText={t("Cancel")}
          title={t("First time on the platform ?")}
          footer={null}
        >
          <p style={{ color: "white" }}>
            {t(
              "It seems that you don't have any sessions yet. Check our tutorial videos to learn how to use the system."
            )}
          </p>
          <div
            style={{
              padding: "75% 0 0 0",
              position: "relative",
              width: "70%",
              margin: "auto",
              marginTop: "10%",
            }}
          >
            <iframe
              src="https://player.vimeo.com/video/786856446?h=4841b01909&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
              title={t("McLloyd ATv1 - Platform overview")}
            ></iframe>
          </div>

          <script src="https://player.vimeo.com/api/player.js"></script>
          <Button
            type="primary"
            style={{ marginBottom: isMobile ? "7%" : "4%", marginTop: "10%" }}
            className="session-form-button"
            onClick={() => {
              navigate(`/${id}/tutorials`, {
                state: {},
              });
            }}
          >
            {t("Check all tutorial videos")}
          </Button>
        </Modal>
      )}
    </>
  );
}

export default withTranslation()(RecordListBySessionMultiple);