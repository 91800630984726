import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.css";
import PopupIndicatorAdd from "../components/PopupIndicatorAdd";
import PopupIndicatorActions from "../components/PopupIndicatorActions";
import { Table } from "antd";
import axios from "axios";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./IndicatorSessionMultiple.css";
import dragula from "dragula";
import DesktopIndicatorValue from "../components/indicators-session-multiple/DesktopIndicatorValue";
import DesktopIndicatorTitle from "../components/indicators-session-multiple/DesktopIndicatorTitle";
import MobileIndicatorTitle from "../components/indicators-session-multiple/MobileIndicatorTitle";
import MobileIndicatorValue from "../components/indicators-session-multiple/MobileIndicatorValue";

var localUnitFactorDistance = localStorage.getItem("distanceUnitFactor");
var localUnitFactorSpeed = localStorage.getItem("speedUnitFactor");

if (localUnitFactorDistance === null) localUnitFactorDistance = "m";
if (localUnitFactorSpeed === null) localUnitFactorSpeed = "km/h";

const IndicatorSessionMultiple = (props) => {
  const { t } = props;
  const location = useLocation();
  const onIndicatorSelected = (indicators) => {
    const items = JSON.parse(localStorage.getItem("indicators"));
    const newItems = JSON.stringify([...items.concat(indicators)]);
    localStorage.setItem("indicators", newItems);
    setDataMobile(
      returnData(JSON.parse(localStorage.getItem("indicators")), false)
    );
    setColumns(returnColumns(JSON.parse(localStorage.getItem("indicators"))));
  };

  const onIndicatorDelete = (indicator) => {
    const items = JSON.parse(localStorage.getItem("indicators"));
    const newItems = JSON.stringify([
      ...items.filter((item) => item !== indicator),
    ]);
    localStorage.setItem("indicators", newItems);
    setDataMobile(
      returnData(JSON.parse(localStorage.getItem("indicators")), false)
    );
    setColumns(returnColumns(JSON.parse(localStorage.getItem("indicators"))));
  };

  const returnData = (indicators, withAthlete) => {
    let mobileData = [];
    if (withAthlete) {
      mobileData.push({ indicator: "Athlete", value: location.state.user });
    }

    const existings = Object.keys(data[0]);
    const used = indicators.filter((indicator) =>
      existings.includes(indicator)
    );

    used.forEach((indic) => {
      for (const [key, value] of Object.entries(data[0])) {
        if (indic && key.toString() === indic.toString())
          mobileData.push({ indicator: key, value: value });
      }
    });
    return mobileData;
  };

  const returnColumns = (indicators) => {
    const cols = [
      {
        title: t("Athlete"),
        dataIndex: "Athlete",
        responsive: ["sm"],
        width: 500,
        render: (_) => (
          <div style={{ marginTop: "10%", marginBottom: "10%" }}>
            {location.state.user}
          </div>
        ),
      },
    ];

    const existings = Object.keys(data[0]);
    const used = indicators.filter((indicator) =>
      existings.includes(indicator)
    );

    used.map((indicatorShown) =>
      cols.push({
        title: () => {
          return (
            <DesktopIndicatorTitle
              indicator={indicatorShown}
              onDelete={onIndicatorDelete}
            />
          );
        },
        dataIndex: indicatorShown,
        key: indicatorShown,
        responsive: ["sm"],
        width: 500,
        render: (value) => (
          <DesktopIndicatorValue value={value} indicator={indicatorShown} />
        ),
      })
    );
    return cols;
  };

  const [data, setData] = useState(location.state.record["indicators"]);
  const [columns, setColumns] = useState(returnColumns([]));
  const [dataMobile, setDataMobile] = useState(returnData([], true));
  const [finalData, setFinalData] = useState(returnData([], true));

  const isMobile = window.innerWidth < 800;

  const getIndexInParent = (el) =>
    Array.from(el.parentNode.children).indexOf(el);
  function swapElements(arr, i1, i2) {
    arr[i1] = arr.splice(i2, 1, arr[i1])[0];
  }
  const handleReorder = (dragIndex, draggedIndex) => {
    setColumns((oldState) => {
      let newState = [...oldState];
      swapElements(newState, dragIndex, draggedIndex);
      let items = [];
      newState.forEach((value) => items.push(value.dataIndex));
      items.pop();
      items.shift();

      const newItems = JSON.stringify([...items]);
      localStorage.setItem("indicators", newItems);
      return newState;
    });
  };

  const onSwapRows = (indicator, sens) => {
    const used = JSON.parse(localStorage.getItem("indicators"));
    const visibles = Object.keys(data[0]);

    // indicator dans la liste utilisé (used)
    // on veux changer indicator avec celui qui est avant/apres lui mais visible
    // donc le suivant dans "used" intersect "data[0]"

    const sourceIndexInUsed = used.indexOf(indicator);

    const usedVisibles = used.filter((ind) => visibles.includes(ind));
    const sourceIndexInUsedVisible = usedVisibles.indexOf(indicator);
    const targetIndexInUsedVisible = sourceIndexInUsedVisible + sens;
    const targetedName = usedVisibles[targetIndexInUsedVisible];
    const targetIndexInUsed = used.indexOf(targetedName);

    swapElements(used, sourceIndexInUsed, targetIndexInUsed);

    const newItems = JSON.stringify([...used]);
    localStorage.setItem("indicators", newItems);
    setDataMobile(
      returnData(JSON.parse(localStorage.getItem("indicators")), false)
    );
  };

  const onSwapRowsUp = (indicator) => {
    onSwapRows(indicator, -1);
  };

  const onSwapRowsDown = (indicator) => {
    onSwapRows(indicator, +1);
  };

  const { id } = useParams();
  const userId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    if( accessToken ){
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
          headers: { "x-access-token": accessToken },
        })
        .then((response) => {
          if (response.data.indicatorsList.length !== 0)
            axios
              .get(
                `${process.env.REACT_APP_SERVER_URL}api/v1/record/${location.state.recordId}`,
                { headers: { "x-access-token": accessToken } }
              )
              .then((res) => {
                if (res.data.indicatorsList.length !== 0) {
                  res.data.indicatorsList.forEach((indicatorShown) => {
                    indicatorShown.toString();
                  });
  
                  localStorage.setItem(
                    "indicators",
                    JSON.stringify(res.data.indicatorsList)
                  );
  
                  setDataMobile(returnData(res.data.indicatorsList), true);
                  setColumns(returnColumns(res.data.indicatorsList));
                } else {
                  response.data.indicatorsList.forEach((indicatorShown) => {
                    indicatorShown.toString();
                  });
  
                  localStorage.setItem(
                    "indicators",
                    JSON.stringify(response.data.indicatorsList)
                  );
                  setDataMobile(returnData(response.data.indicatorsList), true);
                  setColumns(returnColumns(response.data.indicatorsList));
                }
              });
  
          let start1;
          let end1;
  
          const container1 = document.querySelector(".ant-table-thead > tr");
  
          if (!isMobile) {
            const drake1 = dragula([container1], {
              moves: (el) => {
                start1 = getIndexInParent(el);
                return true;
              },
            });
  
            drake1.on("drop", (el) => {
              end1 = getIndexInParent(el);
              handleReorder(start1, end1);
            });
          }
        });
    }
  }, []);

  useEffect(() => {
    setFinalData(dataMobile);
  }, [dataMobile]);

  const mobileColumns = [
    {
      title: "Move",
      key: "index",
      width: "10%",
      render: (row, record, index) => {
        return (
          <>
            {" "}
            {index > 0 ? (
              <ArrowUpOutlined
                style={{ color: "white" }}
                onClick={() => onSwapRowsUp(row.indicator)}
              />
            ) : (
              <></>
            )}{" "}
            {index < dataMobile.length - 1 ? (
              <ArrowDownOutlined
                style={{ color: "white", marginLeft: "20%" }}
                onClick={() => onSwapRowsDown(row.indicator)}
              />
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      title: t("Indicators"),
      key: "indic",
      width: "40%",
      render: (row) => (
        <MobileIndicatorTitle
          indicator={row.indicator}
          onDelete={onIndicatorDelete}
        />
      ),
    },
    {
      title: t("Value"),
      key: "id",
      width: "40%",
      render: (row) => (
        <MobileIndicatorValue value={row.value} indicator={row.indicator} />
      ),
    },
  ];

  var localUnitFactorDistance = localStorage.getItem("distanceUnitFactor");
  var localUnitFactorSpeed = localStorage.getItem("speedUnitFactor");

  if (localUnitFactorDistance === null) localUnitFactorDistance = "m";
  if (localUnitFactorSpeed === null) localUnitFactorSpeed = "km/h";

  const colSize = JSON.parse(localStorage.getItem("indicators")).length * 220;

  return (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{location.state.record.title}</h1>
      <div className="mainContainer">
        {!isMobile ? (
          <>
            <div
              style={{
                textAlign: "right",
                marginBottom: "5%",
                marginLeft: "5%",
                marginRight: "5%",
              }}
            >
              <div
                style={{
                  position: "relative",
                  color: "white",
                  textAlign: "center",
                }}
              >
                {t("Indicators Table")}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    textAlign: "left",
                    flex: "1",
                  }}
                >
                  <PopupIndicatorAdd onSuccess={onIndicatorSelected} />
                </div>
                <div style={{ flex: "1" }}>
                  <PopupIndicatorActions
                    recordId={location.state.recordId}
                    record={location.state.record}
                    indicators={location.state.record["indicators"][0]}
                    trackerName={location.state.trackerId}
                  />
                </div>
              </div>
              <>
                <Table
                  className="table-indicators"
                  columns={columns}
                  dataSource={data}
                  scroll={{
                    x: colSize,
                  }}
                  pagination={false}
                />
              </>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                textAlign: "center",
                marginBottom: "5%",
                marginLeft: "5%",
                marginRight: "5%",
              }}
            >
              <span style={{ color: "#fff" }}>{t("Athlete")} : </span>
              <span style={{ color: "#d5bb64" }}>{location.state.user}</span>
            </div>
            <div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    textAlign: "left",
                    flex: "1",
                    marginLeft: "4%",
                  }}
                >
                  <PopupIndicatorAdd onSuccess={onIndicatorSelected} />
                </div>
                <div style={{ flex: "1", marginRight: "4%" }}>
                  <PopupIndicatorActions
                    recordId={location.state.recordId}
                    record={location.state.record}
                    indicators={location.state.record["indicators"][0]}
                    trackerName={location.state.trackerId}
                  />
                </div>
              </div>
              <>
                <Table
                  pagination={false}
                  className="indicators-table"
                  columns={mobileColumns}
                  dataSource={finalData}
                />
              </>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(IndicatorSessionMultiple);
