import React, { useState, useEffect } from "react";
import { Form, Select, Button, Modal } from "antd";
import axios from "axios";
import { DownloadOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import { Spin } from "antd";
import "./PopupEdit.css";
import { useParams } from "react-router-dom";

function PopupAthletesSelection(props) {
  const { id: userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [athletes, setAthletes] = useState([]);
  const isMobile = window.innerWidth < 700;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        Promise.all([
          response.data.athletes.map(async (athlete) => {
            const idAthlete = athlete.id;
            let fields = {};
            await axios
              .get(
                `${process.env.REACT_APP_SERVER_URL}api/v1/users/${idAthlete}`,
                {
                  headers: { "x-access-token": accessToken },
                }
              )
              .then((res) => {
                if (
                  res.data.roles.filter((usr) => usr.name === "operator")
                    .length === 0
                ) {
                  fields.athleteName = res.data.name;

                  axios
                    .get(
                      `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/athlete/${athlete.id}`,
                      { headers: { "x-access-token": accessToken } }
                    )
                    .then((r) => {
                      fields.trackerName = r.data.name;
                      setAthletes((old) => [...old, fields]);
                    });
                }
              });
          }),
        ]).then(() => setIsLoading(false));
      });
  }, []);

  const Option = Select.Option;

  const { t, onAthleteSelected } = props;

  const onSubmit = (form_data) => {
    onAthleteSelected(form_data.athletes_selected);
    setTimeout(() => {
      setVisible(false);
    }, 1000);
  };
  const onSubmitFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };
  return (
    <div style={{ flex: "1" }}>
      <Button
        type="primary"
        className="session_form"
        style={{
          color: "black !important",
          fontSize: "12px !important",
          width: !isMobile ? "15%" : "100%",
          marginLeft: "3%",
        }}
        onClick={() => {
          showModal();
        }}
      >
        <DownloadOutlined style={{ color: "#d5bb64" }} />
      </Button>
      <Modal
        className="athlete-modal"
        visible={visible}
        title={t("Select Athletes")}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
        footer={null}
      >
        <Form
          name="session_form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onClick={(e) => e.stopPropagation()}
          onFinishFailed={onSubmitFailed}
          className="session_form"
          layout="vertical"
        >
          {isLoading ? <Spin tip={t("Loading ...")}></Spin> : <></>}

          <Form.Item
            name="athletes_selected"
            label={
              <span style={{ fontSize: "14px !important" }}>
                {t("Athletes")}
              </span>
            }
            className="session_input_text"
          >
            <Select
              mode="multiple"
              allowClear
              placeholder={t("Select Athletes")}
              readOnly
            >
              {athletes
                .filter(athlete => athlete.trackerName !== undefined)
                .map((athlete, index) => (
                  <Option value={athlete.trackerName || ''} key={`${athlete.athleteName}-${index}`}>
                    {athlete.athleteName}
                  </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="session-form-button"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default withTranslation()(PopupAthletesSelection);
