import React from "react";
import "antd/dist/antd.css";
import { withTranslation } from "react-i18next";
import { translate } from "../../utils/indicatorTranslate";

const distanceKeys = [
  "Total distance",
  "Sprints total distance",
  "Average distance of sprints",
  "Max. distance of a sprint",
  "Average distance of decelerations",
  "Average distance of accelerations",
  "Distance of accelerations",
  "Distance of decelerations",
  "Di prampero equivalent distance",
  "Distance covered in speed zone 1 (m)",
  "Distance covered in speed zone 2 (m)",
  "Distance covered in speed zone 3 (m)",
  "Distance covered in speed zone 4 (m)",
  "Distance covered in speed zone 5 (m)",
  "Distance covered in speed zone 5 (%)",
  "Distance covered in max speed zone 1 (m)",
  "Distance covered in max speed zone 2 (m)",
  "Distance covered in max speed zone 3 (m)",
  "Distance covered in max speed zone 4 (m)",
  "Distance covered in max speed zone 5 (m)",
  "Time spent in tempo zone 1 (s)",
  "Time spent in tempo zone 2 (s)",
  "Time spent in tempo zone 3 (s)",
  "Time spent in tempo zone 4 (s)",
  "Time spent in tempo zone 5 (s)",
  "Distance covered in VMA Zone 1 (m)",
  "Distance covered in VMA Zone 2 (m)",
  "Distance covered in VMA Zone 3 (m)",
  "Distance covered in VMA Zone 4 (m)",
  "Distance covered in VMA Zone 5 (m)",
];
const speedKeys = [
  "Max speed",
  "Average speed of sprints",
  "Max. speed of a sprint",
  "Average speed",
];

const MobileIndicatorValue = (props) => {
  const { value, indicator} = props;
  
  const localUnitFactorDistance = localStorage.getItem("distanceUnitFactor");
  const localUnitFactorSpeed = localStorage.getItem("speedUnitFactor");
  
  const factorDistance = 
      localUnitFactorDistance === "miles" ? 0.000621371
    : localUnitFactorDistance === "yards" ? 1.09361
    : localUnitFactorDistance === "feets" ? 3.28084
    : 1 ;
  const factorSpeed = 
      localUnitFactorSpeed === "m/s" ? 0.277778
    : localUnitFactorSpeed === "mph" ? 0.621371
    : 1;

  const vNumber = Number(value);
  const isIncorrectValue = isNaN(vNumber) || vNumber === -1;
  const isSpeedValue = speedKeys.includes(translate[indicator]);
  const isDistanceValue = distanceKeys.includes(translate[indicator]);
  const speedValue = Math.round(vNumber * 10 * factorSpeed) / 10 + " " + localUnitFactorSpeed;
  const distanceValue = Math.round(vNumber * 10 * factorDistance) / 10 + " " + localUnitFactorDistance;
  const classicValue = Math.round(vNumber * 10) / 10

  const style =  {
    marginTop: "3%",
    marginBottom: "3%",
  };

  if (indicator === "Athlete") {
    return (
      <div style={style}>
        {value}
      </div>
    );
  } else {
    return (
      <div style={style}>
        {
          isIncorrectValue ? '-'
          : isSpeedValue ? speedValue
          : isDistanceValue ? distanceValue
          : classicValue
        }
      </div>
    );
  }

}

export default withTranslation()(MobileIndicatorValue);
