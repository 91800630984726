import React, { useState } from "react";
import moment from "moment-timezone";
import { Form, Input, Button, TimePicker, Modal } from "antd";
import axios from "axios";
import { NodeIndexOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import "./PopupEdit.css";
import {
  formDateObjectToUTCDate,
  sessionToEndUTCDate,
  sessionToStartUTCDate,
} from "../services/date.service";

function PopupSessionEdit(props) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const { session } = props;

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const onSubmit = (formData) => {
    setLoading(true);
    setVisible(false);

    const endUTCDate    = formDateObjectToUTCDate(formData.end_time._d);
    const startUTCDate  = formDateObjectToUTCDate(formData.start_time._d);

    console.group('[ onSubmit ] data session requested');
    console.table({ startUTCDate, endUTCDate });
    console.table({ _startUTCDate: moment(startUTCDate).utc().toString(), _endUTCDate: moment(endMoment).utc().toString() });

    console.groupEnd();
    // TODO: LP - avoid alert - Need to create a modal
    alert(
      t(
        `Your session will be available in ${getDelay(
          endUTCDate,
          startUTCDate
        )}`
      )
    );

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}api/v1/records/session/${session.id}`,
        {
          // REVIEW: TODO --> ["endTime", "startTime"] it is not "endTime" but "endDate" / unless we extract only the time ( same for startTime )
          endTime: endUTCDate,
          startTime: startUTCDate,
          title: formData.title,
        },
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .catch((error) => console.error(error));
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const showModal = () => {  setVisible(true); };

  const getDelay = (startUTCDate, endUTCDate) => {
    if (startUTCDate && endUTCDate) {
      // TODO : nombre magique : 29.4
      const millis = (startUTCDate.getTime() - endUTCDate.getTime()) / 29.4;
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return (
        minutes + " minutes " + (seconds < 10 ? "0" : "") + seconds + " seconds"
      );
    } else {
      return "";
    }
  };

  const { t, isMobile } = props;

  const sessionStartUTCDate = sessionToStartUTCDate(session);
  const sessionEndUTCDate = sessionToEndUTCDate(session);

  const startMoment = moment(sessionStartUTCDate).local();
  const endMoment   = moment(sessionEndUTCDate).local();

  const changed = {
    start_time: sessionStartUTCDate,
    end_time: endMoment,
  };

  function isCorrectDate(field, _d) {
    const utcDate = formDateObjectToUTCDate(_d);

    const isAfterStart = utcDate >= sessionStartUTCDate;
    const isBeforeEnd = utcDate <= sessionEndUTCDate;
    const isInPeriod = isAfterStart && isBeforeEnd;

    changed[field] = utcDate;
    const isCorrectOrder = changed.start_time < changed.end_time;

    return !isCorrectOrder
      ? Promise.reject(t("start time must be before end time"))
      : !isInPeriod
      ? Promise.reject(t("Must be between start time and end time"))
      : Promise.resolve();
  }

  return (
    <>
      {!isMobile ? (
        <>
          <NodeIndexOutlined
            style={{
              fontSize: "14px !important",
              color: "#d5bb64",
              flex: 1,
              marginRight: "auto",
              marginLeft: "auto",
              marginBottom: "auto",
            }}
            onClick={(event) => {
              event.stopPropagation();
              showModal();
            }}
          />
        </>
      ) : (
        <Button
          style={{ color: "#d5bb64" }}
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        >
          {t("Create a session")}
        </Button>
      )}

      <Modal
        className="athlete-modal"
        visible={visible}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
        title={t("Create a session")}
        footer={null}
      >
        <Form
          name="session_form"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onClick={(e) => e.stopPropagation()}
          onFinishFailed={onSubmitFailed}
          className="session_form"
          layout="vertical"
        >
          <Form.Item
            name="title"
            label={t("Title")}
            initialValue={session.title}
            className="session_input_text"
          >
            <Input name="title" disabled={false} />
          </Form.Item>

          <Form.Item
            name="start_time"
            label={t("Start Time")}
            initialValue={startMoment}
            rules={[
              { required: true, message: t("Please enter start time") },
              {
                validator: (item, startDatetime) =>
                  isCorrectDate(item.field, startDatetime._d),
              },
            ]}
            className="session_input_text"
          >
            <TimePicker
              use12Hours={localStorage.getItem("keyLang") === "en"}
              allowClear={false}
              inputReadOnly={true}
              placeholder={t("Select Time")}
            />
          </Form.Item>
          <Form.Item
            name="end_time"
            initialValue={endMoment}
            label={t("End Time")}
            rules={[
              { required: true, message: t("Please enter end time") },
              {
                validator: (item, endDatetime) =>
                  isCorrectDate(item.field, endDatetime._d),
              },
            ]}
            className="session_input_text"
          >
            <TimePicker
              use12Hours={localStorage.getItem("keyLang") === "en"}
              allowClear={false}
              inputReadOnly={true}
              placeholder={t("Select Time")}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="session-form-button"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default withTranslation()(PopupSessionEdit);
