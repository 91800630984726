import * as React from "react";
import { withTranslation } from "react-i18next";
import { extractCompleteDateFromCSVDate, formatSessionStartDate } from "../../../services/date.service";
import { useNavigate, useParams } from "react-router-dom";

/* --------------------- UX: SIDE MENU > DATA > SESSIONS -------------------- */
function RecordStartItemBySessionMultiple({ record }) {
  const { id } = useParams();
  const navigate = useNavigate();

  /**
   * TODO: Note: *Records Session* time fields are extracted from CSV, 
   *  - which CSV does not have any timezone details,
   *     hence the special need to handle time
   *  --> to fix in the long term by homogenizing all time details
   */
  const formattedDate = extractCompleteDateFromCSVDate(record.startTime);

  const navigateToIndicatorsSessionMultiple = () => {
    navigate(`/${id}/indicatorsSession-multiple`, {
      state: {
        uuid: record.uuid,
        recordId: record.id,
        startTime: record.startTime,
        endTime: record.endTime,
        day: formatSessionStartDate(record),
        trackerId: record.trackerName,
        user: record.athlete.name,
        indicators: record.indicators,
        record: record,
      },
    })
  }

  return (
    <div
      key = {record.uuid}
      onClick = { navigateToIndicatorsSessionMultiple }
      style = {{ marginTop: "3%", marginBottom: "3%", cursor: "pointer" }}
    >
      {formattedDate}
    </div>
  );
}

export default withTranslation()(RecordStartItemBySessionMultiple);
