import React, { useEffect, useRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import PopupAthlete from "../components/PopupAthlete";
import PopupAthleteCreate from "../components/PopupAthleteCreate";
import { useState } from "react";
import axios from "axios";
import { Row, Col, Spin, Input } from "antd";
import { withTranslation } from "react-i18next";

let perChunk = 3;

const ManageAthletes = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [athletes, setAthletes] = useState([]);
  const [search, setSearch] = useState("");

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  console.log("ATHLETES");

  const isMobile = window.innerWidth < 700;

  const { t } = props;

  var tmpAthletes = [];

  const { id } = useParams();
  const userId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  useEffect(() => {
    let id;
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${userId}`, {
        headers: { "x-access-token": accessToken },
      })
      .then((response) => {
        Promise.all([
          response.data.athletes.map(async (athlete) => {
            id = athlete["id"];
            await axios
              .get(`${process.env.REACT_APP_SERVER_URL}api/v1/users/${id}`, {
                headers: { "x-access-token": accessToken },
              })
              .then((res) => {
                console.log(res.data);
                if (
                  res.data.roles.filter((usr) => usr.name === "operator")
                    .length === 0
                )
                  setAthletes((old) =>
                    [...old, res.data].sort((a, b) =>
                      a.id > b.id ? 1 : b.id > a.id ? -1 : 0
                    )
                  );
                tmpAthletes.push(res.data);
              });
          }),
        ]);

        setIsLoading(false);
      })
      .catch((err) => alert(err));
  }, []);

  return isLoading ? (
    <Spin tip="Loading ..."></Spin>
  ) : (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("Athletes")}</h1>
      <div className="mainContainer">
        <div
          style={{ textAlign: "left", marginBottom: "5%", paddingTop: "5%" }}
        >
          <Input
            style={{
              width: "auto",
              backgroundColor: "transparent",
              color: "white",
              border: "1px solid #4D4E4E",
              borderRadius: "6px",
            }}
            name="name"
            placeholder={t("Search by name")}
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>
        <div style={{ textAlign: "left", marginBottom: "5%" }}>
          {!isMobile
            ? athletes

                .filter((athlete) => {
                  return (
                    !search ||
                    (athlete.name &&
                      athlete.name.toLowerCase().indexOf(search.toLowerCase()) >
                        -1)
                  );
                })
                .reduce((resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / perChunk);

                  if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                  }

                  resultArray[chunkIndex].push(item);

                  return resultArray;
                }, [])
                .map((athleteChunk,idx) => (
                  <div key={`ManageAthletes-${idx}`}>
                    <Row
                      style={{ backgroundColor: "transparent" }}
                      align="middle"
                    >
                      {athleteChunk.map((athlete, index) => {
                        console.log(athlete);
                        return (
                          <Col
                            key={index}
                            type="flex"
                            className="athleteName"
                            align="middle"
                            span={8}
                          >
                            {" "}
                            <PopupAthlete
                              key={`PopupAthlete-${index}`}
                              style={{
                                verticalAlign: "middle",
                              }}
                              athlete={athlete}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                ))
            : athletes
                .filter((athlete) => {
                  return (
                    !search ||
                    (athlete.name &&
                      athlete.name.toLowerCase().indexOf(search.toLowerCase()) >
                        -1)
                  );
                })
                .map((athl, index) => (
                  <Col
                    key={index}
                    type="flex"
                    className="athleteName"
                    align="middle"
                    span={24}
                  >
                    {" "}
                    <PopupAthlete
                      key={`PopupAthlete-${index}`}
                      style={{
                        verticalAlign: "middle",
                      }}
                      athlete={athl}
                    />
                  </Col>
                ))}
          <div
            style={{
              textAlign: "center",
              height: "auto",
              textTransform: "none",
              fontSize: "14px",
              color: "black",
              border: "0px",
              borderRadius: "7px",
              marginRight: isMobile ? "35%" : "40%",
              marginLeft: isMobile ? "35%" : "40%",
              marginTop: "5%",
              paddingBottom: "11%",
            }}
          >
            <PopupAthleteCreate />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ManageAthletes);
