import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  DatePicker,
  Modal,
  message,
  Radio,
} from "antd";
import axios from "axios";
import { withTranslation } from "react-i18next";
import PopupAthleteDelete from "./PopupAthleteDelete";
import "./PopupEdit.css";
import moment from "moment";
import { useParams } from "react-router-dom";

function PopupAthlete(props) {
  const [form] = Form.useForm();
  const [trackers, setTrackers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { athlete } = props;

  const isMobile = window.innerWidth < 700;

  const { id } = useParams();
  const coachId = id;
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));

  const photo = athlete.photo ? athlete.photo : require("../assets/coach.png");
  const Option = Select.Option;

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}api/v1/trackers/coach/${coachId}`,
        {
          headers: { "x-access-token": accessToken },
        }
      )
      .then((response) => {
        setTrackers(response.data);
      });
    setLoading(false);
  }, []);

  const onSubmit = (form_data) => {
    setLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}api/v1/users/${athlete.id}`,

        {
          firstName: form_data.firstName,
          lastName: form_data.lastName,
          name: form_data.name === undefined ? athlete.name : form_data.name,
          color: form_data.color,
          gender: form_data.gender,
          birthdate: form_data.birthdate._d,
          height: Number(form_data.height),
          weight: Number(form_data.weight),
          email:
            form_data.email === undefined ? athlete.email : form_data.email,
          password: form_data.password,
          vmax: Number(form_data.vmax),
          amax: Number(form_data.amax),
          fcmax: Number(form_data.fcmax),
          fclow: Number(form_data.fclow),
          vma: Number(form_data.vma),
          vo2max: Number(form_data.vo2max),
          power_event_thresold: Number(form_data.power_event_thresold),
          capability: Number(form_data.capability),
          trackerId: form_data.tracker,
        },
        { headers: { "x-access-token": accessToken } }
      )
      .then(
        (res) => {
          console.log("Success create athlete");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            error.response?.data?.message || error.message || error.toString();
          message.error(resMessage);
        }
      )
      .catch((error) => console.log(error));
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };
  const onSubmitFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleChange = (value) => {};

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setVisible(false);
  };

  const { t } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: !isMobile ? "40%" : "50%",
        }}
      >
        <img
          src={photo}
          style={{
            fontSize: "100%",
            flex: 3,
            cursor: "pointer",
            borderRadius: "60%",
          }}
          alt="profile"
          onClick={(event) => {
            event.stopPropagation();
            showModal();
          }}
        />
        <p
          style={{
            color: "white",
            fontSize: "14px !important",
            flex: "1",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {athlete.name}
        </p>
      </div>
      <Modal
        className="athlete-modal"
        visible={visible}
        title={
          <>
            <span className="titleEdit">{t("Edit Athlete")}</span>
              <PopupAthleteDelete athlete={athlete} />
          </>
        }
        onOk={handleOk}
        cancelText={t("Cancel")}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="session_form"
          initialValues={{ remember: true }}
          form={form}
          onFinish={onSubmit}
          onClick={(e) => e.stopPropagation()}
          onFinishFailed={onSubmitFailed}
          className="session_form"
          layout="vertical"
        >
          <Form.Item
            name="firstName"
            label={t("First Name")}
            initialValue={athlete.firstName}
            className="session_input_text"
          >
            <Input name="firstName" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={t("Last Name")}
            initialValue={athlete.lastName}
            className="session_input_text"
          >
            <Input name="lastName" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="name"
            label={t("Surname")}
            rules={[{ required: true, message: t("Please enter surname") }]}
            initialValue={athlete.name}
            className="session_input_text"
          >
            <Input name="name" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="tracker"
            label="Tracker"
            initialValue={athlete.tracker ? athlete.tracker.id : null}
          >
            <Select
              name="tracker"
              placeholder={t("Attach tracker (optional)")}
              readOnly
            >
              {trackers.map((tracker,idx) => {
                console.log(tracker);
                return (
                  <Option value={tracker.id} key={idx}>
                    {tracker.name} -{" "}
                    {tracker.athlete ? tracker.athlete.name : t("No User")}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="gender"
            label={t("Gender")}
            initialValue={athlete.gender}
            className="session_input_text"
          >
            <Radio.Group
              name="gender"
              disabled={loading}
              onChange={(e) => handleChange(e.target.value)}
            >
              <Radio value="male">{t("Male")}</Radio>
              <Radio value="female">{t("Female")}</Radio>
              <Radio value="other">{t("Other")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="birthdate"
            label={t("Birth date")}
            rules={[{ required: true, message: t("Please enter birth date") }]}
            className="session_input_text"
            initialValue={moment(athlete.birthdate)}
          >
            <DatePicker
              placeholder={t("Select Date")}
              disabledDate={(current) => moment() <= current}
              onChange={(value) => handleChange(value)}
            />
          </Form.Item>
          <Form.Item
            name="height"
            label={t("Height")}
            initialValue={athlete.height}
            className="session_input_text"
            rules={[
              {
                required: true,
                message: t("Please enter height"),
              }
            ]}
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="Height"
              min={0}
              max={500}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="weight"
            label={t("weight")}
            initialValue={athlete.weight}
            className="session_input_text"
            rules={[
              {
                required: true,
                message: t("Please enter weight"),
              }
            ]}
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="weight"
              min={0}
              max={500}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("Email")}
            initialValue={athlete.email}
            className="session_input_text"
            rules={[
              { required: true, message: t("Please enter email") },
              {
                type: "email",
                message: t("Please enter a valid email address"),
              },
            ]}
          >
            <Input name="email" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="password"
            label={t("Password (Optional)")}
            className="login_input_text"
            initialValue={""}
          >
            <Input.Password
              disabled={loading}
              autoComplete="new-password"
              type="password"
              placeholder={t("Password")}
            />
          </Form.Item>
          <Form.Item
            name="vmax"
            label="Vmax (km/h)"
            initialValue={athlete.vmax}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="vmax"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="amax"
            label="Amax (m/s2)"
            initialValue={athlete.amax}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="amax"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="fcmax"
            label={t("FCmax (bpm)")}
            initialValue={athlete.fcmax}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="fcmax"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="fclow"
            label={t("FC Repos (bpm)")}
            initialValue={athlete.fclow}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="fclow"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="vma"
            label="VMA (km/h)"
            initialValue={athlete.vma}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="vma"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="vo2max"
            label="VO2max (ml/min/kg)"
            initialValue={athlete.vo2max}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="vo2max"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="power_event_thresold"
            label={t("Power event thresold (Watts/kg)")}
            initialValue={athlete.power_event_thresold}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              step={0.01}
              name="power_event_thresold"
              min={0}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="capability"
            label={t("Athletic capability")}
            initialValue={athlete.capability}
            className="session_input_text"
          >
            <InputNumber
              type="number"
              onWheel={(e) => e.target.blur()}
              name="capability"
              min={1}
              max={10}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="session-form-button-save"
            >
              {t("Save")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default withTranslation()(PopupAthlete);
