import * as React from "react";
import { withTranslation } from "react-i18next";
import { extractCompleteDateFromCSVDate, formatSessionStartDate, formatCompleteDate} from "../../../services/date.service";
import { useNavigate, useParams } from "react-router-dom";

/* ------------------------ UX: MENU > DATA > SESSIONS** ----------------------- */
function RecordEndItemBySessionMultiple({ record }) {
  const { id } = useParams();
  const navigate = useNavigate();
  
  /**
   * TODO: Note: *Records* time fields are from DB returning ISO times values, 
   *  - which does have timezone details
   *     hence the need to handle time with extractCompleteDateFromCSVDate
   *  --> to fix in the long term by homogenizing all time details
  */
  const formattedDate = extractCompleteDateFromCSVDate(record.endTime);

  const navigateToIndicatorsSessionMultiple = () => {
    navigate(`/${id}/indicatorsSession-multiple`, {
      state: {
        uuid: record.uuid,
        recordId: record.id,
        startTime: record.startTime,
        endTime: record.endTime,
        day: formatSessionStartDate(record),
        trackerId: record.trackerName,
        user: record.athlete.name,
        indicators: record.indicators,
        record: record,
      },
    })
  }

  return (
    <div
      key = {record.uuid}
      onClick = { navigateToIndicatorsSessionMultiple }
      style = {{ marginTop: "3%", marginBottom: "3%", cursor: "pointer" }}
    >
      { formattedDate }
    </div>
  );
}

export default withTranslation()(RecordEndItemBySessionMultiple);
