import * as React from "react";
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { useState, useEffect } from "react";
import axios from "axios";
import { Spin } from "antd";
import "./ViewByRecordMultiple.css";
import RecordListBySessionMultiple from "../components/multiple-trackers/list/RecordListBySessionMultiple";
import { withTranslation } from "react-i18next";

const API_VERSION = 'v1';
const REQ_URL = `${process.env.REACT_APP_SERVER_URL}api/${API_VERSION}`
const getREQ_HEADERS = accessToken => ({ headers: { "x-access-token": accessToken } });

const ViewByRecordMultiple = ({ t }) => {
  let { id: userId } = useParams();
  userId = JSON.parse(userId);

  const accessToken = JSON.parse(localStorage.getItem("accessToken"));
  const REQ_HEADERS = getREQ_HEADERS(accessToken);
  
  const [recordsBySession, setRecordsBySession] = useState([]);
  const [loadingSession, setLoadingSession] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(!!userId){
      axios
        .get(`${REQ_URL}/users/${userId}`, REQ_HEADERS)
        .then( response => {
          setCount(response.data.athletes.length);
          localStorage.setItem("keyLang", response.data.language);
          localStorage.setItem("speedUnitFactor", response.data.speedUnit);
          localStorage.setItem("distanceUnitFactor", response.data.distanceUnit);
          localStorage.setItem(
            "indicators",
            JSON.stringify(response.data.indicatorsList)
          );
          let athletes = response.data.athletes;
          
          // Gets athlete & their trackers
          Promise.all([
            // GET athlete/:id 
            athletes.map(async ({ id, name, email, user_athletes }, idx) => {
              await axios.get(`${REQ_URL}/records/athlete/${id}`, REQ_HEADERS)
                .then((res) => {

                  res.data.createdAt= moment(res.data.createdAt).utc();
                  res.data.updatedAt= moment(res.data.updatedAt).utc();
                  res.data.startTime= moment(res.data.startTime).utc();
                  res.data.endTime = moment(res.data.endTime).utc();
                  console.log('res.data.endTime:', res.data.endTime.toString())
                  if(!!res.data.length) {
                    setRecordsBySession((prevState) => [
                      ...prevState,
                      res.data,
                    ]);
                  }
                }).catch( console.error )

              // TODO: LP --> (coach support debug) extra tracker request to remove
              // GET trackers/athlete/:id 
              await axios.get(
                `${REQ_URL}/trackers/athlete/${id}`, REQ_HEADERS)
                .then(({ data: tracker }) => {
                  if( id !== userId) {
                    console.group(`Athlete #${idx} | ${name} | id: ${id} | tracker: ${tracker.name}` );
                    console.table({ id, name, email, user_athletes, tracker: tracker.name });
                    console.groupEnd();
                  }
                });
            }),
          ]).then(() => setLoadingSession(false));
        });
    }
  }, []);

  return loadingSession && !!recordsBySession.length ? (
    <Spin tip={t("Loading ...")}></Spin>
  ) : (
    <div style={{ textAlign: "center", backgroundColor: "#262626" }}>
      <h1>{t("Sessions")}</h1>
      <div className="mainContainer">
        <RecordListBySessionMultiple recordsWithTracker={recordsBySession} />
      </div>
    </div>
  );
};

export default withTranslation()(ViewByRecordMultiple);
