import * as React from "react";
import { withTranslation } from "react-i18next";
import { formatSessionStartDate, formatSessionEndDateTime, formatSessionStartDateTime } from "../../../services/date.service";
import { useNavigate, useParams } from "react-router-dom";
import PopupRecordDelete from "../../PopupRecordDelete";

function RecordMobileItemBySessionMultiple(props) {
  const {t, record} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        fontSize: "14px !important",
        paddingTop: "4% !important",
        paddingBottom: "4% !important",
      }}
      key={record.uuid}
    >
      <p
        onClick={() =>
          navigate(`/${id}/indicatorsSession-multiple`, {
            state: {
              uuid: record.uuid,
              recordId: record.id,
              startTime: record.startTime,
              endTime: record.endTime,
              day: formatSessionStartDate(record),
              trackerId: record.trackerName,
              user: record.athlete.name,
              indicators: record.indicators,
              record: record,
            },
          })
        }
        style={{
          fontSize: "14px !important",
          marginBottom: "4% !important",
        }}
      >
        <span style={{ color: "#d5bb64" }}>{t("Title")}: </span>{" "}
        {record.title}
      </p>
      <br />
      <p
        onClick={() =>
          navigate(`/${id}/indicatorsSession-multiple`, {
            state: {
              uuid: record.uuid,
              recordId: record.id,
              startTime: record.startTime,
              endTime: record.endTime,
              day: formatSessionStartDate(record),
              trackerId: record.trackerName,
              user: record.athlete.name,
              indicators: record.indicators,
              record: record,
            },
          })
        }
        style={{
          fontSize: "14px !important",
          marginBottom: "4% !important",
        }}
      >
        <span style={{ color: "#d5bb64" }}>{t("Athlete")}: </span>{" "}
        {record.athlete.name}
      </p>
      <br />
      <p
        onClick={() =>
          navigate(`/${id}/indicatorsSession-multiple`, {
            state: {
              uuid: record.uuid,
              recordId: record.id,
              startTime: record.startTime,
              endTime: record.endTime,
              day: formatSessionStartDate(record),
              trackerId: record.trackerName,
              user: record.athlete.name,
              indicators: record.indicators,
              record: record,
            },
          })
        }
        style={{
          fontSize: "14px !important",
          marginBottom: "4% !important",
        }}
      >
        <span style={{ color: "#d5bb64" }}>{t("Start")}: </span>{" "}
        {formatSessionStartDateTime(record)}
      </p>
      <br />
      <p
        onClick={() =>
          navigate(`/${id}/indicatorsSession-multiple`, {
            state: {
              uuid: record.uuid,
              recordId: record.id,
              startTime: record.startTime,
              endTime: record.endTime,
              day: formatSessionStartDate(record),
              trackerId: record.trackerName,
              user: record.athlete.name,
              indicators: record.indicators,
              record: record,
            },
          })
        }
        style={{
          fontSize: "14px !important",
          marginBottom: "4% !important",
        }}
      >
        <span style={{ color: "#d5bb64" }}>{t("End")}: </span>{" "}
        {formatSessionEndDateTime(record)}
      </p>
      <br />
      <PopupRecordDelete isMobile={true} session={record} />
    </div>
);
}

export default withTranslation()(RecordMobileItemBySessionMultiple);
